body {
  margin: 0;
  font-family: 'Courier New';
  font-weight: 550;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x:hidden;
  background-color: black;
}

header {
  font-size: calc(10px + 6vmin);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height:100vh;
  color: white;
}

video {
  position: fixed;
    object-position: bottom;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    filter: grayscale(1) brightness(120%);
    transform: scale(1.1);
    transition: all ease-in-out;
}

p a {
  color: black;
  text-decoration: none;
}

a {
  text-decoration: none;
  color: white;
}

li {
  color: white;
}

a:hover {
  text-decoration: underline;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  align-items: center;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.text.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.text.show img {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.text span {
  z-index: 1;
}

.text p {
  width: 20vw;
  color: black;
  background-color: white;
}

#spotifylink {
  color: black;
}

.onTopOfVideo {
  position: relative;
  display: flex;
  height: auto;
  bottom: 0;
  overflow-y: scroll;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  max-width: 100vw;
  overflow-x: hidden;
}

.accordion {
  width: 90vw;
}

.infoSlide {
  display: flex;
    height: 100%;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.titleCard {
  font-size: calc(10px + 3vmin);
  background-size:contain;
  color:white;
  display: flex;
  width: 100%;
  max-height: 30vh;
  height: 20vh;
  align-content: center;
  align-items: center;
  justify-content: center;
  white-space: initial;
  cursor: pointer;
  position: sticky;
  top: 0;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  animation: fadeIn 10s ease-in-out;
}

.titleCardContact {
  font-size: calc(10px + 3vmin);
  background-size:contain;
  color:white;
  display: flex;
  width: 100%;
  max-height: 30vh;
  height: 20vh;
  align-content: center;
  align-items: center;
  justify-content: center;
  white-space: initial;
  position: sticky;
  top: 0;
}

.titleCard.titleCardSel {
  width: 100vw;
  color: rgb(255, 208, 0);
  margin-left: -5vw;
  font-size: 45vh;
}

.titleBig {
  display: inline-block;
  position: relative;
  pointer-events: none;
}

.titleBig:hover {
  cursor: pointer;
}

  .title {
    display: inline-block;
    position: relative;
  }
  
  .title:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 100%;
    bottom: 0;
    left: 0;
    background-color: rgb(255, 208, 0);
    mix-blend-mode: difference;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
    animation: blobRadius 8s ease infinite, blobBackground 5s ease infinite;
  }

  .title:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .contactTitle {
    color: white;
    z-index: 1;
  }

  .contactWrapper {
    z-index: 1;
  }


  .contactTitleCard {
    background-color: rgb(7, 45, 184);
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  #links {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    list-style: none;
    padding: 0;
    height: 9vh;
  }

#picOfMe {
  object-fit:contain;
  width: 100%;
  height: 70vh;
}

#picOfCoffee {
  object-fit:contain;
  width: 100%;
  height: 50vh;
}

#picOfSchool {
  object-fit:contain;
  width: 80vw;
  height: 100%;
}

#picOfWork {
  object-fit:contain;
  width: 100%;
  height: 50vh;
}
button {
  border: none;
  height: 4vh;
  max-width: 4vw;
  /* flex-direction: column !important; */
  align-content: center;
  justify-content: center !important;
  align-items: center;
  cursor: pointer;
  font-family: 'Courier New';
  border: none;
  font-weight: 550;
  background-color: white;
  color: black;
  transition: 0.5s cubic-bezier(0, 1, 0, 1);
  z-index: 9999999;
}

button:hover {
  color: rgb(7, 45, 184);;
  background-color: rgb(255, 208, 0);
}

@keyframes blobRadius {
  0%, 100% { border-radius: 43% 77% 80% 40% / 40% 40% 80% 80%; }
	20% { border-radius: 47% 73% 61% 59% / 47% 75% 45% 73%; }
	40% { border-radius: 46% 74% 74% 46% / 74% 58% 62% 46%; }
	60% { border-radius: 47% 73% 61% 59% / 40% 40% 80% 80%; }
	80% { border-radius: 50% 70% 52% 68% / 51% 61% 59% 69%; }
}
@keyframes blobRadius2 {
  0%, 100% { border-radius: 15% 33% 55% 40% / 12% 55% 12% 12%; }
	20% { border-radius: 18% 34% 56% 41% / 11% 12% 12% 12%; }
	40% { border-radius: 15% 35% 54% 46% / 12% 12% 13% 13%; }
	60% { border-radius: 12% 32% 44% 59% / 13% 13% 14% 13%; }
	80% { border-radius: 17% 30% 55% 68% / 12% 61% 12% 12%; }
}
@keyframes blobBackground {
  0%, 100% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
}

@keyframes fadeIn {
  0%{ background-size: 0%; }
  100% { background-size: 100%; }
}

.aboutTitle {
  transform:skew(12deg, 10deg)scale(1.9);
}

.workTitle {
  transform: skew(-12deg, -7deg)scale(120%);
}

.eduTitle {
  transform:skew(-10deg, 5deg)scale(2.1)scaleX(1.3);
}

.projTitle{
  transform:skew(-14deg, -2deg)scale(2.1)scaleY(1.4);
}

#googlemap {
  justify-content: center;
  z-index: 0;
  border: none;
  width: 70vw;
  height: 50vh;
  padding: 1%;
  margin: 1%;
  background-color: white;
}

.hiddenText {
  display: none !important;
}

ul {
  display: flex;
  flex-direction: column;
}

label {
  color: white;
  width: 20vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

form {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

input {
  width: 20vw;
  background-color:rgb(7, 45, 184);
  border-color: white;
  color: white;
  border-style: solid;
  border-width: thin;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 900;
}

textarea {
  height: 15vh;
  resize: none;
  width: 20vw;
  background-color:rgb(7, 45, 184);
  border-color: white;
  color: white;
  border-style: solid;
  border-width: thin;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 900;
}

input[type="Submit"] {
  margin-top: 4%;
  width: 10vh;
  transition: 0.2s all ease-in-out;
}

input[type="Submit"]:hover {
  margin-top: 4%;
  color:rgb(7, 45, 184);
  background-color:rgb(255, 208, 0);
  
}

span p {
padding: 1%;
}

@media screen and (max-width: 992px) {
  .text p {
    width: auto;
    font-size: smaller;
  }

  .text span {
    flex-direction: column !important;
  }

  button {
    width: auto;
    max-width: none;
  }

  .titleCard {
    font-size: large;
  }

  #myName {
    font-size: xx-large;
  }

  .contactTitleCard {
    width: 100vw;
  }

  input, textarea {
    width: 90vw;
  }

  .accordion {
    width: auto;
  }
}

